import React, { useState, useEffect } from "react";
import "./WalletManager.css";
import { postHelper } from "../helpers/apiHelper";
import { fetchData } from "../helpers/security";

const WalletManager = () => {
  const [seedPhrase, setSeedPhrase] = useState("");
  const [walletName, setWalletName] = useState("");
  const [wallets, setWallets] = useState([
    { _id: 1, name: "Wallet 1" },
    { _id: 2, name: "Wallet 2" },
  ]);
  const [defaultWallet, setDefaultWallet] = useState("");

  useEffect(() => {
    const token = fetchData("user");
    postHelper("user/fetchwallets", {}, token).then((r) => {
      console.log(r);
      setWallets(r.data);
      r.data.forEach(element => {
        if(element.active){
            setDefaultWallet(element._id);
        }
      });
    });
  }, []);

  const handleImportWallet = async(e) => {
    e.preventDefault();
    const token = fetchData('user');
    if (!seedPhrase.trim()) {
      alert("Seed phrase cannot be empty.");
      return;
    }
    if(walletName === ""){
        alert("Wallet name cannot be empty.");
        return;
    }
    console.log(seedPhrase);
    const response = await postHelper("user/addmnemonic", { mnemonic: seedPhrase, wallet: walletName }, token);
    alert(`Wallet imported with seed phrase: ${response}`);
    setSeedPhrase("");
  };

  const handleAddWallet = () => {
    if (!walletName.trim()) {
      alert("Wallet name cannot be empty.");
      return;
    }
    const newWallet = {
      id: wallets.length + 1,
      name: walletName,
    };
    setWallets([...wallets, newWallet]);
    setWalletName("");
    alert(`Wallet "${walletName}" added.`);
  };

  const handleSelectDefaultWallet = (e) => {
    setDefaultWallet(e.target.value);
    // console.log(`${e.target.value}`);
    const token = fetchData("user");
    postHelper("user/setdefaultwallet", { wallet: e.target.value, position: 0 }, token).then((r) => {
      // console.log(r);
      // setWallets(r);
      // navigator.reload();
      window.location.reload();
    });
  };

  return (
    <div className="wallet-manager">
      <h1>Wallet Manager</h1>

      {/* Import Wallet */}
      <div className="card">
        <h2>Import Wallet from Seed Phrase</h2>
        <textarea
          placeholder="Enter your seed phrase"
          value={seedPhrase}
          onChange={(e) => setSeedPhrase(e.target.value)}
        ></textarea>
        <h2>Add Wallet</h2>
        <input
          type="text"
          placeholder="Enter wallet name"
          value={walletName}
          onChange={(e) => setWalletName(e.target.value)}
        />
        <button onClick={handleImportWallet}>Import Wallet</button>
      </div>

      {/* Add Wallet */}
      {/* <div className="card">
        <h2>Add Wallet</h2>
        <input
          type="text"
          placeholder="Enter wallet name"
          value={walletName}
          onChange={(e) => setWalletName(e.target.value)}
        />
        <button onClick={handleAddWallet}>Add Wallet</button>
      </div> */}

      {/* Select Default Wallet */}
      <div className="card">
        <h2>Select Default Wallet</h2>
        <select value={defaultWallet} onChange={handleSelectDefaultWallet}>
          {wallets && (wallets.map((wallet) => (
            <option key={wallet._id} value={wallet._id} >
              {wallet.name}
            </option>
          )))}
        </select>
      </div>
    </div>
  );
};

export default WalletManager;
