import { decrypt, encrypt } from "./security";
import {bnbkeys} from "../constants/keys";


const baseUrl = "https://rpcapi.edenwallet.app/";
// const baseUrl = "http://192.168.29.66:5000/";
// const axios = require('axios');

function safeParse(input) {
    try {
        // Check if the input is a string
        if (typeof input === 'string') {
            // Try parsing it to JSON
            const parsed = JSON.parse(input);

            // Check if it successfully parsed to an object/array
            if (typeof parsed === 'object' && parsed !== null) {
                return parsed; // Return the parsed object
            }
        }
    } catch (error) {
        // Catch JSON.parse errors
        console.error("Invalid JSON string:", error.message);
    }

    // Return the input as-is if it's not a valid stringified JSON
    return input;
}

const postHelper = async (url, data, header) => {
    return fetch(baseUrl + url, {
        method: 'POST',
        headers: {
            'x-auth-token': header,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(encrypt(data)),
    })
    .then((response) => response.json())
    .then((responseJson) => {
        // console.log(decrypt(responseJson));
        return safeParse(decrypt(responseJson))
    })
    .catch((error) => {
        console.error(error);
    });
}

const priceHelper = async () => {
    try {
        const response = await fetch('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,LTC,DOGE,ETH,MATIC,BNB,USDT,DASH,TRX,ARB&tsyms=USD');
        
        const prices = await response.json();
        // console.log('Current Prices in USD:');
        // console.log(`BTC: $${prices.bitcoin.usd}`);
        // console.log(`LTC: $${prices.litecoin.usd}`);
        // console.log(`DOGE: $${prices.dogecoin.usd}`);
        // console.log(`ETH: $${prices.ethereum.usd}`);
        // console.log(`MATIC: $${prices.polygon.usd}`);
        return prices;
      } catch (error) {
        // console.error('Error fetching crypto prices:', error);
        return false;
      }
}


// Function to select a random key
const getRandomKey = (keyArray) => {
    const randomIndex = Math.floor(Math.random() * keyArray.length);
    return keyArray[randomIndex];
};


const historyHelper = async (address) => {
    const randomKey = getRandomKey(bnbkeys);
    try{
        const response = await fetch(`https://api.bscscan.com/api?module=account&action=txlist&address=${address}&page=1&offset=50&sort=desc&apikey=${randomKey}` );
        const prices = await response.json();
        return prices;
    }catch (error) {
        return false;
    }
}

export {postHelper, priceHelper, historyHelper}