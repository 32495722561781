import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {menuModal} from '../reducers/actions/menuaction'
import { ArrowDownLeft, ArrowUpRight, ArrowLeftRight, Copy } from 'react-bootstrap-icons';
import QRCode from "react-qr-code";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const MenuModal = () => {
  const menu = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
    <Modal
      show={menu.isModalOpen}
      onHide={() => dispatch(menuModal())} // Add a function to close the modal if needed
      centered
      scrollable
      dialogClassName="modal-sm"
      backdrop={true}
    >
      <Modal.Body>
        <h1 className="mb-4" style={{ fontSize: 25 }}>
          <span className="text-secondary fw-light">{t('quick')}</span>
          <br />
          {t('actions')}
        </h1>
        <div className="text-center">
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%" }}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={menu.userData ? menu.userData.networks.address : 'N/A'}
            viewBox={`0 0 256 256`}
          />
        </div>
          <p className="text-secondary mb-4" style={{ fontSize: 10 }}>
          {menu.userData ? menu.userData.networks.address : 'N/A'} <Copy style={{cursor: 'hand', color: 'black'}} onClick={() => {navigator.clipboard.writeText(menu.userData ? menu.userData.data.address : 'N/A'); toast.success("Copied!")}}/>
          </p>
        </div>
        <div className="row justify-content-center mb-4">
          
          <div className="col-auto text-center" >
            <Link to="/send"
              
              className="avatar avatar-70 p-1 shadow-sm shadow-purple rounded-20 bg-opac mb-2"
              data-bs-dismiss="modal"
            >
              <div className="icons text-purple">
                <ArrowUpRight size={24} />
              </div>
            </Link>
            <p className="size-10 text-secondary">{t('send')}</p>
          </div>

          <div className="col-auto text-center">
            <Link to="/receive"
              className="avatar avatar-70 p-1 shadow-sm shadow-success rounded-20 bg-opac mb-2"
              data-bs-dismiss="modal"
            >
              <div className="icons text-success">
                <ArrowDownLeft size={24} />
              </div>
            </Link>
            <p className="size-10 text-secondary">{t('receive')}</p>
          </div>
          <div className="col-auto text-center">
            <a
              href="#"
              className="avatar avatar-70 p-1 shadow-sm shadow-danger rounded-20 bg-opac mb-2"
              data-bs-dismiss="modal"
            >
              <div className="icons text-danger">
                <ArrowLeftRight size={24}/>
              </div>
            </a>
            <p className="size-10 text-secondary">{t('swap')}</p>
          </div>
        </div>
        
        
      </Modal.Body>
    </Modal>

    <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // transition= "bounce"    
            />
    </>
  );
};

export default MenuModal;
