import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowDownLeft, ArrowUpRight } from 'react-bootstrap-icons';
import { postHelper } from '../../helpers/apiHelper';
import { fetchData } from '../../helpers/security';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {coins} from '../../constants/coins';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import {historyHelper} from '../../helpers/apiHelper';
import TimeAgo from "./timeago";

const Transactiondata = () => {
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 25; // Data per page
    const [count, setCount] = useState(25);
    const { t } = useTranslation();
    const menu = useSelector((state) => state.menu);


    const decodeInputData = (input) => {
        // Remove the "0x" prefix if present
        const cleanInput = input.startsWith("0x") ? input.slice(2) : input;

        // Extract the method ID (first 4 bytes)
        const methodId = cleanInput.slice(0, 8);

        // Extract the topics (parameters)
        const encodedParameters = cleanInput.slice(8);

        // Decode parameters
        const toAddress = `0x${encodedParameters.slice(24, 64)}`; // Address is right-padded
        const value = parseInt(`0x${encodedParameters.slice(64, 128)}`); // Value is uint256

        return value;
    };

    // Calculate total pages based on the count
    useEffect(() => {
        setTotalPages(Math.ceil(count / pageSize));
    }, [count]);

    // Fetch transactions for the current page
    useEffect(() => {
      const token = fetchData('user');
      const fetch  = async () => {
        const skip = (currentPage - 1) * pageSize;
        if(menu.userData && menu.userData.data.address != undefined){
            const res = await historyHelper(menu.userData.networks.address);
            console.log(res.result);
            setTransactions(res.result);
            setCount(res.result.count);
        }

      };
      fetch();
    }, [currentPage, menu.userData]);

    const [selectedCoin, setSelectedCoin] = useState(coins[0].name); // Set initial selection

  const handleSelect = (eventKey) => {
    const selected = coins.find(coin => coin.name === eventKey);
    setSelectedCoin(selected.name); // Update selected coin name
  };

    // Handler for changing pages
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Generate the page numbers for pagination (Google-style)
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbersToShow = 5; // Max number of page links to display
        let startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
        let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

        // Adjust startPage if we are at the last few pages
        if (endPage - startPage + 1 < maxPageNumbersToShow) {
            startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
        }

        // Add page numbers to the array
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`btn ${i === currentPage ? 'btn-primary' : 'btn-light'} me-2`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    return (
      
        <>
        {transactions.length > 0 ? 
        <>
        <div className="row mb-2">
            <div className="col">
                <h6 className="title">{t('recenttransactions')} ({selectedCoin})</h6>
            </div>
            {/*<div className="col-auto align-self-center">*/}

            {/*<DropdownButton*/}
            {/*  id="dropdown-basic-button"*/}
            {/*  title={selectedCoin}*/}
            {/*  onSelect={handleSelect}*/}
            {/*  style={{ color: 'black !important' }}*/}
            {/*  className="custom-dropdown"*/}
            {/*  align="end" */}
            {/*>*/}
            {/*  {coins.map((coin) => (*/}
            {/*    <Dropdown.Item*/}
            {/*      key={coin.id}*/}
            {/*      eventKey={coin.name}*/}
            {/*      style={{ fontSize: '11px' }}*/}
            {/*    >*/}
            {/*      <img src={coin.image} alt={coin.name} width="20" height="20" className="me-2" />*/}
            {/*      {coin.name} ({coin.symbol})*/}
            {/*    </Dropdown.Item>*/}
            {/*  ))}*/}
            {/*</DropdownButton>*/}

            {/*</div>*/}
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card shadow-sm mb-4">
                    <ul className="list-group list-group-flush bg-none">
                        {transactions.length > 0 ?
                            <>
                            {transactions.map((transaction, index) => (
                                <li key={index} className="list-group-item">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="card mbhide">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-44 rounded-15">
                                                        {
                                                            transaction.to == "0x5c1826e96f6c5aa6b0a01b20cf6feab192b6ae5e" || transaction.from == "0x5c1826e96f6c5aa6b0a01b20cf6feab192b6ae5e" ?(
                                                                <img src="/logo.png" alt="logo"/>):
                                                                transaction.to == "0x55d398326f99059ff775485246999027b3197955" || transaction.from == "0x55d398326f99059ff775485246999027b3197955"?
                                                                    (<img src="https://cryptologos.cc/logos/tether-usdt-logo.png?v=032" alt="logo"/>): (
                                                                        <img src="https://cryptologos.cc/logos/bnb-bnb-logo.png?v=032" alt="logo"/>
                                                                    )

                                                        }
                                                        <img src="/logo.png" alt="logo" />
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="card mbshow">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-44 rounded-15">
                                                        {transaction.from == menu.userData.data.address ? <ArrowDownLeft size={24} /> : <ArrowUpRight size={24} />}
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col px-0">
                                            <p className='ellipsify' onClick={() => window.open(`https://bscscan.com/tx/${transaction.hash}`)}>
                                                {transaction.hash}
                                                <br />
                                                <small className="text-secondary">{transaction.input == "0x" ? <>BNB Transaction</> : <>BEP20 Transaction</>}</small>
                                                <small className={`text-secondary`}><TimeAgo timestamp={transaction.timeStamp} /></small>
                                            </p>
                                        </div>
                                        <div className="col-auto text-end">
                                            <p>
                                                <small className="text-secondary">
                                                    <div>
                                                        <p>
                                                            {
                                                                transaction.to == "0x5c1826e96f6c5aa6b0a01b20cf6feab192b6ae5e" || transaction.from == "0x5c1826e96f6c5aa6b0a01b20cf6feab192b6ae5e" ?(
                                                                        <>EDN</>):
                                                                    transaction.to == "0x55d398326f99059ff775485246999027b3197955" || transaction.from == "0x55d398326f99059ff775485246999027b3197955"?
                                                                        (<>USDT</>): (
                                                                            <>BNB</>
                                                                        )

                                                            }
                                                        </p>
                                                        <p>{transaction.input == "0x"?
                                                            <>{transaction.value / 10 ** 18}</>:
                                                            decodeInputData(transaction.input) / 10 ** 18
                                                        } {
                                                            transaction.to == "0x5c1826e96f6c5aa6b0a01b20cf6feab192b6ae5e" || transaction.from == "0x5c1826e96f6c5aa6b0a01b20cf6feab192b6ae5e" ?(
                                                                    <>EDN</>):
                                                                transaction.to == "0x55d398326f99059ff775485246999027b3197955" || transaction.from == "0x55d398326f99059ff775485246999027b3197955"?
                                                                    (<>USDT</>): (
                                                                        <>BNB</>
                                                                    )

                                                        }</p>
                                                    </div>
                                                </small>
                                            </p>
                                        </div>
                                        <div className="col-auto mbhide">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-44 rounded-15">
                                                        {transaction.to.toLowerCase() == menu.userData.data.address.toLowerCase() ? <ArrowDownLeft size={24} /> : <ArrowUpRight size={24} />}
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            </> : <></>
                        }
                    </ul>
                </div>
            </div>
        </div>

        {/* Pagination Controls */}
        <div className="row mb-2">
            <div className="col text-center">
                <button
                    className="btn btn-light me-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    {t('previous')}
                </button>
                
                {renderPageNumbers()}
                
                <button
                    className="btn btn-light"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    {t('next')}
                </button>
            </div>
        </div>
        </> : <Loader /> }
        </>
    );
};

export default Transactiondata;
